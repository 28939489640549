// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://api.vermarsh.com/',
  discoveryCallLink: "https://topmate.io/vermarsh/564094",
  linkedin: "https://www.linkedin.com/in/vermarsh/",
  instagram: "https://www.instagram.com/vermarsh.in/",
  github: "https://github.com/vermarsh",
  fb_token: '1//0gNvTpg29pTBBCgYIARAAGBASNwF-L9IrTL_UwIR_boMxYRqGjUbjAgEUskElTdHLGuUDQTpvJVdpGdg9KMffGbKfPLm2hxN-GRs'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
