import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'shared', loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule) },
  { path: '', loadChildren: () => import('./pages/industries/industries.module').then(m => m.IndustriesModule) },
  { path: '', loadChildren: () => import('./pages/services/services.module').then(m => m.ServicesModule) },
  { path: '', loadChildren: () => import('./pages/solution/solution.module').then(m => m.SolutionModule) },
  

];
  

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
