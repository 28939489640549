import { Injectable } from "@angular/core";

@Injectable()
export class apiList {

  // GET APIs
  getBlog: string = "api/v1/user/getBlogs";
  getCms: string = 'api/v1/user/getCms';
  uploadFile: string = '';

  //post APIs
  addLead: string = 'api/v1/user/addLeads';

}
