import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatIconModule} from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service';
import { CurrencyPipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { apiList } from './services/https/api-list';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { HttpClientModule } from '@angular/common/http';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#6d659e',
  bgsSize: 40,
  bgsType: SPINNER.wanderingCubes,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    HttpClientModule,
  
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    apiList,
    CurrencyPipe,
    NgxUiLoaderService,
    // MatDialog,
    // Overlay,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
