import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  zoneData = [];
  geofenceData = [];
  backToListUrl = "/taxi/admin";
  accessDetails: any;

  constructor(private currencyPipe: CurrencyPipe) {}

  presentsToast(type: any, position: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 2000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  confirmToast() {
    Swal.fire({
      title: "Are you sure?",
      customClass: {
        popup: 'colored-toast'
      },
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#a38d23",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  openDialog(type:any, message:string) {
    return Swal.fire({
      title: "Are you Sure?",
      customClass: {
        popup: 'colored-toast'
      },
      text: message,
      icon: type,
      showCancelButton: true,
      confirmButtonColor: "#a38d23",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
  }

  errorDialog(type:any, message:string) {
    return Swal.fire({
      title: "Error!",
      customClass: {
        popup: 'colored-toast'
      },
      text: message,
      icon: type,
      showCancelButton: false,
      confirmButtonColor: "#a38d23",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
    });
  }

  currencyFormat(number:number) {
    if (number == 0 || number || !number) {
      return "$0.00";
    }
    return new Intl.NumberFormat().format(number);
    if (number == 0) {
      return "0.00";
    } else {
    return this.currencyPipe.transform(number, "USD");
    return number.toLocaleString("en-US");
    }
  }
}
